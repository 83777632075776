import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import axios from "axios"

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Current Openings",
  deleteOn
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  const [Job, setJob] = useState([])
  //after useeffect calling Job variable has athe data of database

  const deleteTask = async (id) => {
    try {
      await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}Job/deleteJob/${id}`,
      });

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const a1 = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}Job/allJob`,
        });
        setJob(a1.data);
        //Job is a constant variable, hence set Job is used.
        //setJob is used to edit Job variable.
        // console.log(a1);
      } catch (err) {
        console.log(err);
      }
    }
    fetchTask()
  }, [Job]);


  console.log(Job)
  const posts = Job.map((item) => {
    return {
      title: item.jobTitle,
      description: item.jobDesc,
      category: item.jobSnippet,
      date: item.createdAt,
      imageSrc: "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
      url: `/SpecificJob/${item._id}`,
      //We are returning underscore id beacuse mongo return _id only not id
      id: item._id
    }
  })

  const buttonstyle = {
    "background-color": "green",
    "text-transform": "uppercase",
    "cursor": "pointer",
    "margin": "30px 30px",
    "padding": "5px 10px 5px 10px",
    "borderRadius": 5,
    "color": "#FFFFFF"
  }

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  {/* <Image imageSrc={post.imageSrc} /> */}
                  <Info>
                    <Category>Job ID: {post.category}</Category>
                    <CreationDate>Job Created on : {post.date.substr(0, 10)}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
                {deleteOn ? <button style={buttonstyle} className="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={()=>{deleteTask(post.id)}}>Delete</button> : ""}
              </PostContainer>
              
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};