import React from "react";
import ReactDOM from 'react-dom';
import tw from "twin.macro";
import styled from "styled-components";
import axios from "axios"
import { useState } from 'react';
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/quotes-r.svg";
import RichTextEditor from '../richtexteditor/RichTextEditor';
import {stateToHTML} from 'draft-js-export-html';
import {Editor, EditorState , convertToRaw} from 'draft-js';
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`flex-row mx-auto`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`
// const editorstyle = {

// }
// const textstyle = {
//   display: flex;
//   justify-content: center; 
// }
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span style={{ color: "#398e3d" }}>get in touch</span><wbr /> with us.</>,
  description = "If you wish to meet people who work in a friendly, non - corporate office environment to witness a quality working experience that inspires everyone then let's have a chat over a cup of coffee.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  const richtexteditorstyle = {
    "width": "1000px"
  }
  const descstyle = {
    padding: "100px 10px 10px 10px",
    border: "5px solid black"
  }
  const [title, setTitle] = useState('')
  const [snippet, setSnippet] = useState('')
  const [desc, setDesc] = useState(
    () => EditorState.createEmpty(),
  );
  const submit = async (e) => {
    e.preventDefault()
    const blocks = stateToHTML(desc.getCurrentContent());
    console.log(blocks);
    if (!title && !blocks && !snippet) {
      alert("title and description cant be blank");
    } else {
      console.log(title);
      console.log(snippet);
      
      try {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}Job/createJob`,
          data: {
            jobTitle: title,
            jobSnippet: snippet,
            jobDesc: blocks
            //databse field and coreespnoding to that what inpout i am taking 
          },
        });
        alert("new Job created");
      } catch (err) {
        console.log(err);
      }
    }
    
  }
  return (
    <Container >
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          
          <TextContent>

            <Heading>{"Write your entry"}</Heading>
            <Form onSubmit={submit}>
              <Input type="text" name="name" placeholder="Job name" value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
              <Input type="text" name="name" placeholder="Job deadline" value={snippet} onChange={(e)=>{setSnippet(e.target.value)}}/>
              {/* TODO: Add a Rich Text Editor here */}
              <RichTextEditor style = {richtexteditorstyle} setVar={setDesc} />
              {/* <Textarea type="text" name="name" placeholder="Write the description of Job" value={desc} onChange={(e)=>{setDesc(e.target.value)}}/> */}

              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};


