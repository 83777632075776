import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import InnovationImage from "images/support-icon.svg";
import ImpactImage from "images/shield-icon.svg";
import RespectImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      {/* <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      /> */}
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="We live in a world where technology has changed everything about our lives. It's no longer enough to have a job; now we must find meaning in what we do. To do so, we aim to make a culturally rich company."
        cards={[
          {
            imageSrc: InnovationImage,
            title: "Trust",
            description: "Team members should be encouraged to express their opinions and share their views openly and honestly. There should be no fear of criticism or punishment for expressing one’s opinion. Building trust enables people to speak up without fearing reprisal and it helps us to get things done together as a team."
          },
          {
            imageSrc: ImpactImage,
            title: "Equality",
            description: "Our culture advocates fairness in the work environment. All staff are expected to behave professionally and respectfully towards each other. All employees should receive fair treatment regardless of their gender, race, religion, age, disability, and sexual orientation. All employees should treat each other with respect, dignity, and justice at all times."
          },
          {
            imageSrc: RespectImage,
            title: "Work-Life Balance",
            description: "CodeJocks provides opportunities for employees to balance work and family life. This includes flexible working arrangements, part-time work, and essential leaves. Building a home for creativity and innovation is the key to building a physical culture. Every employee is given the freedom to achieve their objectives in a way that makes them feel valued and appreciated."
          }
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
