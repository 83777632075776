import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import InnovationImage from "images/support-icon.svg";
import ImpactImage from "images/shield-icon.svg";
import RespectImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading style={{color: "#398e3d"}}>The Rebirth</Subheading>}
        heading="And we're back"
        buttonRounded={false}
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description="Now you would be curious to know what made us start CodeJocks again?

        Well, there are many reasons for it. 
        
        You’ll probably agree with the notion that entrepreneurs are not born, rather they are made. One of the most important aspects of becoming an entrepreneur material person is gaining real-life business knowledge. Likewise, from the time CodeJocks was not working i.e 2018 till today, Ritvik gained a lot of exposure by working at various MNC(s). His experience increased as he worked as a freelancer, consultant and entrepreneur. The best of his learning probably came from establishing a startup. He got an epiphany that this might be the right time to restart CodeJocks and he embarked on the journey to execute this idea of developing a product-centric startup incubator. And now we’re all ready to make our goals a reality."
      />
      <MainFeature1
        subheading={<Subheading style={{color: "#398e3d"}}>The Breaking</Subheading>}
        heading="Good things fall apart sometimes"
        buttonRounded={false}
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        description="While staying faithful to CodeJocks’s fundamental beliefs, Ritvik and Abhishek decided to dissolve the company due to a diminishing interest in the service sector. He wanted the CodeJocks to design and produce products and companies of its own, thus CodeJocks was temporarily closed after this realization in early 2018."
      />
      <MainFeature1
        subheading={<Subheading style={{color: "#398e3d"}}>The Working</Subheading>}
        heading="The approach is more essential than the outcome."
        buttonRounded={false}
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description="In CodeJocks, a large portion of the functioning individuals including Ritvik were in school while running the organization. So they weren’t playing by the rules while operating the company. The timings were 4 PM to 10 PM. (I mean who wouldn’t wanna work in a company that lets you sleep till late in the morning? LOL!). Other than this there were occasional movie sessions ( with Hot Popcorns and BeanBags for seats!). The atmosphere and workspace always had a personal touch because each member of the team was close and worked together as a family. People at CodeJocks worked with an optimistic attitude, effective management and strong teamwork.

        CodeJocks started showing significant development and grew more business. CodeJocks Technologies Pvt. Ltd. got incorporated on 20th April 2017. There was stability and scope for CodeJocks. Since then, there was 70 % month-on-month growth and the team size grew 2x in numbers.
        
        By the completion of the third quarter in 2017, CodeJocks had onboarded a minimum of ten partners and they had completed 30+ projects."
      />
      <MainFeature1
        subheading={<Subheading style={{color: "#398e3d"}}>The Hurdles</Subheading>}
        heading="Nothing exceptional has ever come easily"
        buttonRounded={false}
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        description="Running into obstacles while building a business plan is inevitable. To successfully establish one’s vision, one must face and overcome several hurdles.

        Similarly, the CodeJocks team experienced numerous challenges.
        
        One of the crucial ones; creating a good team. Businesses can only prosper when teams collaborate on a common task or goal. Bringing in strong team players helps to shape the CodeJocks’s culture and helps the business mature.
        
        Personal connections and networks came through during this crisis. Managing work time was another major challenge since most of the team members including Ritvik were in college. Taking the additional mile is never too tough when there is passion and a strong purpose, and that was the spirit with which the CodeJocks team battled."
      />
      <MainFeature1
        subheading={<Subheading style={{color: "#398e3d"}}>The ORIGIN</Subheading>}
        heading="The beginning of everything"
        buttonRounded={false}
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description="The core of a company’s story is its History. CodeJocks started when two clear-eyed, passionate and innovative tech – nerds, Ritvik Vyas and Abhishek Chandra, decided to collaborate. (FUN INSIGHT – Can you guess where these two tech-savvy men met? At an IT event called “Android Pilgrimage”. Interestingly enough, Ritvik was the event’s organizer and Abhishek ended up winning the contest.)

        Following this “meet-cute,” CodeJocks was developed from Ritvik and Abhishek’s combined experiences as budding entrepreneurs. They worked their way up to construct a solid foundation for their nestling businesses. They started with the goal of assisting those who are looking for help getting CodeJocks off the ground. The vision of CodeJocks was to create powerful multipurpose products with a clean business style. Right from the beginning, they ensured that they understand the requirements of each client while offering services tailored to meet their needs. With a powerful idea, an entrepreneurial drive, and pure determination, on 1 Aug 2016 CodeJocks came into existence as a service domain company in the service sector based in Jaipur."
      />
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: InnovationImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ImpactImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: RespectImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
