import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line


import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";

import InnovationImage from "images/zap.svg";
import ImpactImage from "images/move.svg";
import RespectImage from "images/loader.svg";
import ProblemImage from "images/check-circle.svg"
import CommunicationImage from "images/users.svg"
import ResponseImage from "images/trending-up.svg"
import CollaborationImage from "images/plus-circle.svg"
import TimeImage from "images/clock.svg"
import HelpfulImage from "images/smile.svg"
import CreativityImage from "images/pen-tool.svg"
import AccountabilityImage from "images/command.svg"
import WorkImage from "images/sunrise.svg"
import IntegrityImage from "images/plus.svg"
import TrustImage from "images/user-plus.svg"
import AgilityImage from "images/triangle.svg"
import ValuesImage from "images/sun.svg"
import EmpathyImage from "images/heart.svg"

const Subheading = tw.span`uppercase tracking-wider text-sm `;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading style={{color: "#398e3d"}}>Mission</Subheading>}
        heading=" To convert top-notch ideas into high-quality products"
        buttonRounded={false}
        // primaryButtonText="See Portfolio"
        description = "We aspire to create a new generation of businesses and industry leaders. We go by the fundamental concept of providing services to our very own range of products, in which case we take all the necessary steps to enhance and optimize the implementation of those ideas. We wish to create a creative room for people who want to make a difference. We seek to provide our intrapreneurs with the best-in-class mentorship to empower and allow them to focus on their core capabilities and achieve favorable outcomes contributing to the overall development of humanity."
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading style={{color: "#398e3d"}}>Vision</Subheading>}
        heading="To work towards ideas that can create a purposeful impact on the world"
        buttonRounded={false}
        // primaryButtonText="Contact Us"
        description = "We want to empower our intrapreneurs to help them realize their full potential and bring innovative concepts to life. We seek to set up and cultivate business models to produce companies with the potential to become the next FAANGs of the world, based on our three pillars of Design, Develop, and Deploy."
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      {/* <MainFeature1
        subheading={<Subheading>Core Values</Subheading>}
        heading="CodeJocks is the epitome of guidance and consultation."
        buttonRounded={false}
        // primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      /> */}
      {/* <MainFeature1
        subheading={<Subheading>Key Principles</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        // primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      /> */}
      <Features
        subheading={<Subheading>Core Values</Subheading>}
        heading="I.T.A.V.E."
        description=" We believe in creating a culturally rich company"
        cards={[
          {
            imageSrc: IntegrityImage,
            title: "Integrity",
            description: "We are honest, reliable, and never afraid to admit our mistakes. We believe in choosing the right thing even if it’s the harder option at times. "
          },
          {
            imageSrc: TrustImage,
            title: "Trust",
            description: "We believe that concerns related to trust have the power to make or break an organization's culture. We keep talks and communications open and ensure that everyone on the team expresses their concerns and feedback without fear of being judged or retaliated against. "
          },
          {
            imageSrc: AgilityImage,
            title: "Agility",
            description: "We are flexible enough to accept change and adapt to it. We know that every situation is unique and requires a unique solution."
          },
          {
            imageSrc: ValuesImage,
            title: "Value Centric",
            description: 'We strive to deliver quality products and services. We firmly believe in the principle of "what you do, do it well"'
          },
          {
            imageSrc: EmpathyImage,
            title: "Empathy",
            description: "We show genuine interest and concern in other people's needs, expectations, and dreams. When you embed empathy into the fabric of your team then you can count on increased productivity, morale, and loyalty."
          },
        ]}
        linkText=""
      />
      <Features
        subheading={<Subheading>Key Principles</Subheading>}
        heading="We share one common ideology: we value our job and our coworkers"
        description="We believe in creating an environment that is full of fun and creativity by adhering to a few basic principles that help us develop ways to improve productivity."
        cards={[
          {
            imageSrc: InnovationImage,
            title: "Innovation",
            description: "We constantly strive to challenge ourselves into our better and more effective versions. Through innovation, we generate solutions for business and raise the bar."
          },
          {
            imageSrc: ImpactImage,
            title: "Impact",
            description: "We aim to work with a well reasoned view in a way that each team member is always excited for work and to create something with a strong impact."
          },
          {
            imageSrc: RespectImage,
            title: "Respect Values",
            description: "We want to be known as a company that values respect, integrity, humility, empathy,  creativity, and fun. We have deeply embedded these values in our work culture and processes."
          },
          {
            imageSrc: ProblemImage,
            title: "Problem Solver",
            description: "Our aim is to develop modern and precise solutions to the business problems. With the right attitude and skill set we aim to identify and eliminate problems while monitising them to create sustainable businesses."
          },
          {
            imageSrc: CommunicationImage,
            title: "Communication",
            description: "We believe in open communication channels. It is critical for establishing trust among team members. Open communication allows employees to contribute their opinions about potential changes and improvements in the workplace and projects."
          },
          {
            imageSrc: ResponseImage,
            title: "Responsiveness",
            description: "We make sure to find solutions to each issue we come across as soon as possible. Whether it's an employee or a client, we promptly reply to all the inquiries. We pay close attention to the requirements of each individual associated with our company."
          },
          {
            imageSrc: CollaborationImage,
            title: "Collaboration",
            description: "We value teamwork and believe in working together towards achieving common goals. We encourage communication and collaboration amongst ourselves and our clients."
          },
          {
            imageSrc: TimeImage,
            title: "Time Management",
            description: "If a workplace is able to foster a strong sense of respect for time among the employees then it helps a lot in the operation of the workplace smoothly."
          },
          {
            imageSrc: HelpfulImage,
            title: "Helpful",
            description: "Sharing knowledge can always open some new doors of opportunities. We never run away from helping our colleagues or taking help from them. It helps us gain more experience doing a wide variety of tasks."
          },
          {
            imageSrc: CreativityImage,
            title: "Creativity",
            description: "In the workplace, creativity contributes to problem solving and assists in the development of innovative concepts. We don't set too many limitations for our employees at work so that they can come up with unique thoughts and solutions."
          },
          {
            imageSrc: AccountabilityImage,
            title: "Accountibility",
            description: "We believe in the notion that people who are held accountable are more likely to take initiative and become better team players. If something goes wrong, they're less prone to complain or blame others."
          },
          {
            imageSrc: WorkImage,
            title: "Work Hard and Play Hard",
            description: "We value hard work and play hard when we have downtime. We take breaks as needed, but we always get back into the swing of things when we return."
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
